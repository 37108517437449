import React from 'react'
import logo from '../assets/navigation-logo.png';
import { ArrowLeft } from './icons';

export default (props) => (
  <header>
    <div className="inner">
      <a href="https://isobar.com/au/en/">
        <img className="logo" src={logo} alt="Uplift logo" />
      </a>
    </div>
  </header>
)
