import React from 'react'
import { Helmet } from 'react-helmet';

import '../styles/main.scss'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer'

class Template extends React.Component {
  render() {

    const { location, children } = this.props;
    const title = this.props.title ? `Uplift Series  | ${this.props.title}` : 'Uplift Series';

    let rootPath = `/`;
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`;
    }

    let linkToIndex = false;

    if (this.props.linkToIndex) {
      linkToIndex = this.props.linkToIndex;
    }

    return (
      <Container>
        <Helmet>
          <title>{title}</title>
          <link rel="icon" type="image/png" href="/icon.png"></link>
          <meta name="theme-color" content="#fafafa"></meta>
        </Helmet>
        <Navigation linkToIndex={linkToIndex} />
        <main>{children}</main>
        <Footer />
      </Container>
    )
  }
}

export default Template
